<template>
  <v-container fluid style="margin-bottom: 100px">
    <v-row>
      <v-col cols="12">
      <div class="d-flex">
          <v-breadcrumbs :items="breadcumbs">
              <template v-slot:item="{ item }">
                  <v-breadcrumbs-item
                      :to="item.href"
                      class="text-subtitle-2 crumb-item"
                      :disabled="item.disabled"
                      exact
                  >
                      {{ item.text }}
                  </v-breadcrumbs-item>
              </template>
          </v-breadcrumbs>
          <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
              <v-layout align-center pr-4>
              <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
              <v-layout column>
                  <div>
                  <strong>{{ snackbar.title }}</strong>
                  </div>
                  <div>{{ snackbar.text }}</div>
              </v-layout>
              </v-layout>
              <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
              <v-icon>clear</v-icon>
              </v-btn>
          </v-snackbar>
          <v-spacer></v-spacer>
      </div>
      </v-col>
    </v-row>
    
  <!-- <v-container fluid class="d-flex flex-column" style="min-height: calc(100vh - 100px);"> -->
    <v-row align="center" justify="center" >
      <v-col cols="12">
                <v-card class="p-2" outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    v-model="periode"
                                    :items="periodes"
                                    item-value="periode_id"
                                    item-text="periode"
                                    label="periode"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>

                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="region"
                                    :items="regions"
                                    item-value="region_id"
                                    item-text="descr"
                                    label="Region"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => getSubRegion(event)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="subregion"
                                    :items="subregions"
                                    item-value="subregion_id"
                                    item-text="descr"
                                    label="Sub Region"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => getOffice(event)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="office"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    label="Office"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            
                            <!-- tanggal pick dari -->
                            <v-col cols="12" xs="12" sm="2" md="2">
                              <v-menu
                                ref="modal"
                                v-model="modal"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    solo
                                    v-model="date_from"
                                    :label="$t('labels.dateFrom')"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 pa-0 border-12"
                                    hide-details="true"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-if="periode==='Date' || periode==='' || !periode "
                                  v-model="date_from"
                                  no-title
                                  @input="modal = false"
                                ></v-date-picker>
                                <v-date-picker
                                  v-if="periode=='Month'"
                                  v-model="date_from"
                                  type="month"
                                  @input="modal = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>


                            <!-- tanggal pick sampai -->
                            <v-col cols="12" xs="12" sm="2" md="2">
                              <v-menu
                                ref="modal_to"
                                v-model="modal_to"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    solo
                                    v-model="date_to"
                                    :label="$t('labels.dateTo')"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 pa-0 border-12"
                                    hide-details="true"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-if="periode==='Date' || periode==='' || !periode"
                                  v-model="date_to"
                                  no-title
                                  @input="modal_to = false"
                                ></v-date-picker>
                                <v-date-picker
                                  v-if="periode=='Month'"
                                  v-model="date_to"
                                  type="month"
                                  @input="modal_to = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-btn class="border-12" style="padding: 20px;"
                                color="info" elevation="2" small @click="getSalesAnalysis()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col class="col-12" sm="6" md="4">
        <v-card outlined height="370">
            <v-card-text>
                <v-img
                class="text-center mx-auto mt-12"
                lazy-src="@/assets/empty_data.png"
                max-width="200"
                src="@/assets/empty_data.png"
                v-if="showDataKategori"
                ></v-img>
                <div id="container1" style="height: 300px; width: 100%;"></div>
            </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12" sm="6" md="4">
        <v-card outlined height="370">
            <v-card-text>
                <v-img
                class="text-center mx-auto mt-12"
                lazy-src="@/assets/empty_data.png"
                max-width="200"
                src="@/assets/empty_data.png"
                v-if="showDataKomoditiEksternal"
                ></v-img>
                <div id="container2" style="height: 300px; width: 100%;"></div>
            </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12" sm="6" md="4">
        <v-card outlined height="370">
            <v-card-text>
                <v-img
                class="text-center mx-auto mt-12"
                lazy-src="@/assets/empty_data.png"
                max-width="200"
                src="@/assets/empty_data.png"
                v-if="showDataKomoditiInternal"
                ></v-img>
                <div id="container3" style="height: 300px; width: 100%;"></div>
            </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col class="col-12" sm="6" md="3">
        <v-card outlined height="370">
            <v-card-text>
                <v-img
                class="text-center mx-auto mt-12"
                lazy-src="@/assets/empty_data.png"
                max-width="200"
                src="@/assets/empty_data.png"
                v-if="showDataKomoditiJasa"
                ></v-img>
                <div id="container4" style="height: 300px; width: 100%;"></div>
            </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
    <!-- diagram bawah -->
    <v-row class="mt-2">
      <v-col
        cols="12"
        xs="12" 
        sm="12" 
        md="12"
      >
        <v-card class="pa-md-5">   
          <div id="container5" style="height: 370px; width: 100%;"></div>
          <p></p>
        </v-card>
        
        <!-- dialog pendaftaran -->
        <v-dialog v-model="dialog_inv_payment" max-width="1000px">                
          <v-card class="bg_card">
              <v-progress-linear
                      :active="loading_inv_payment"
                      :indeterminate="loading_inv_payment"
                      absolute
                      bottom
                      color="deep-purple accent-4"
              ></v-progress-linear>
              <v-card-title>Sales Detail</v-card-title>
              <v-card-text>
                    <v-container fluid>
                      <v-row>
                          <v-col cols="12">
                              <!-- <v-card-title>Item Pendaftaran
                                  <v-spacer></v-spacer>
                                  <v-text-field
                                      v-model="$store.state.text"
                                      append-icon="mdi-magnify"
                                      label="Search..."
                                      single-line
                                      @keyup.enter="search_inv_payment"
                                      hide-details
                                  ></v-text-field>
                              </v-card-title> -->
                              <v-data-table
                                  dense
                                  :headers="header_item_inv_payments"
                                  :items="data_pendaftarans"
                                  class="elevation-1 mt-2"
                                  :items-per-page="10"
                                  fixed-header
                                  :divider="true"
                                  :light="true"
                              >
                              <template v-slot:[`item.TglPesan`]="{ item }">
                                {{ (new Date(new Date(item.TglPesan) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                              </template>
                              <template v-slot:[`item.Lembar`]="{ item }">
                                  {{ $store.getters.convertToCurrencyNoFrac(item.Lembar) }}
                              </template>
                              <template v-slot:[`item.HargaSatuan`]="{ item }">
                                  {{ $store.getters.convertToCurrencyNoFrac(item.HargaSatuan) }}
                              </template>
                              <template v-slot:[`item.Total`]="{ item }">
                                  {{ $store.getters.convertToCurrencyNoFrac(item.Total) }}
                              </template>
                              </v-data-table>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close_inv_payment">Close</v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col class="col-12" sm="6" md="12">
        <v-data-table
          dense
          :headers="header_sales"
          :items="data_sales"
          class="elevation-1 mt-2"
          :items-per-page="10"
          fixed-header
          :divider="true"
          :light="true">
          <template v-slot:top>
            <v-toolbar flat color="white" class="mt-2 mb-2">
                <div class="d-flex w-100">
                    <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> Sum Office </h6>
                </div>
            </v-toolbar>
          </template>
          <template v-slot:[`item.total`]="{ item }">
            {{ $store.getters.convertToCurrencyNoFrac(item.total) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
              <td>
                  <v-tooltip top>
                      <template v-slot:activator="{ on }">
                          <v-icon small class="mr-2" @click="detail(item)" v-on="on">
                              mdi-send
                          </v-icon>
                      </template>
                      <span>Detail</span>
                  </v-tooltip>
              </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="mt-2">
    <!-- <v-row class="flex-grow-1"> -->
      <v-col class="col-12" sm="6" md="12">
        <v-data-table
            dense
            :headers="header_item_inv_payments"
            :items="data_pendaftarans"
            class="elevation-1 mt-2"
            :items-per-page="10"
            fixed-header
            :divider="true"
            :light="true"
        >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mt-2 mb-2">
              <div class="d-flex w-100">
                  <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Detail </h6>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    color="#005c37"
                    class="py-5 mr-3 text-white"
                    @click="exportExcel()">
                      <v-icon>mdi-file-excel</v-icon>
                      <download-excel
                        class="text"
                        :fetch           = "exportExcel"
                        :fields="headersColumn"
                        :before-generate = "startDownload"
                        :before-finish   = "finishDownload">
                        Export Excel
                    </download-excel>
                  </v-btn>
              </div>
          </v-toolbar>
      </template>
        <template v-slot:[`item.TglPesan`]="{ item }">
          {{ (new Date(new Date(item.TglPesan) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
        </template>
        <template v-slot:[`item.Lembar`]="{ item }">
            {{ $store.getters.convertToCurrencyNoFrac(item.Lembar) }}
        </template>
        <template v-slot:[`item.HargaSatuan`]="{ item }">
            {{ $store.getters.convertToCurrencyNoFrac(item.HargaSatuan) }}
        </template>
        <template v-slot:[`item.Total`]="{ item }">
            {{ $store.getters.convertToCurrencyNoFrac(item.Total) }}
        </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="mt-2" style="flex-grow: 1; height: 5%;">
      <v-col class="col-12" sm="6" md="12">
        <div style="height: 100%; width: 100%;"></div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import {kriMaster} from "@/backend-api/kri/kriMaster"
import {salesAnalysis} from "@/backend-api/kri/salesAnalysis"
import {backendApi} from "@/backend-api/backend-api-sr"
// import {converterLibrary} from "@/library/converterLibrary"

export default {
  data() {
      return {
        breadcumbs: [
            {
                text: 'Kencana Ritelindo Indonesia',
                disabled: false,
                href: '/admin/kri',
            },
            {
                text: 'Sales',
                disabled: true,
            },
            {
                text: 'Sales Analysis',
                disabled: true,
            }
        ],
        snackbar: {
            color: null,
            icon: null,
            mode: null,
            position: "top",
            text: null,
            timeout: 7500,
            title: null,
            visible: false
        },
        periode:'Date',
        periodes:[
          'Date',
          'Month',
        ],
        region:'',
        regions: [],
        subregion:'',
        subregions: [],
        office:'',
        offices: [],
        display: 'none',
        period_other_payment: '',
        dialog_other_payment: false,

        loading_other_payment: false,
        total_item_other_payment: 10,
        
        period_inv: '',
        dialog_inv_payment: false,
        pagination_inv_payment: 1,
        period: '',
        dialog_payment: false,
        pagination_payment: 1,
        loading_payment: false,
        
        option_item_payments:{},
        loading_inv_payment: false,
        
        total_item_inv_payments: 10,
        pagination_inv_payment: 1,
        header_item_inv_payments:[
          {
              text: 'Order Number',
              align: 'start',
              sortable: true,
              value: 'CustomerOrderNo',
          },
          { text: 'Tanggal', value: 'TglPesan' },
          { text: 'Office', value: 'office' },
          { text: 'Barang', value: 'NamaBarang' },
          { text: 'Qty', value: 'Lembar', align: 'right' },
          { text: 'Harga', value: 'HargaSatuan', align: 'right' },
          { text: 'Total', value: 'Total', align: 'right' },
        ],
        data_pendaftarans: [],
        header_sales:[
          { text: 'Office', value: 'office', width: '30%' },
          { text: 'Total', value: 'total', align: 'right' },
          { text: 'Action', value: 'actions', sortable: false, width: '10%'},
        ],
        headersColumn: {
            'Order No': 'CustomerOrderNo',
            'Tanggal Pesan': 'TglPesan',
            'Office': 'office',
            'Nama Barang': 'NamaBarang',
            'Qty': 'Lembar',
            'Harga': 'HargaSatuan',
            'Total': 'Total'
        },
        data_sales: [],
        option_item_inv_payments:{},
        shop_summary_lastest: [],

        dialog_detail_paket: false,
        pagination_shop: 1, 
        last_month: '',
        loading_shop_last_month: false,
        detail_shop_summary_lastest: [],
        total_item_shops: 10,
        option_item_shops:{},
        date_from:'',
        modal: false,
        date_to: "",
        modal_to: false,
        showDataKategori: true,
        showDataKomoditi: true,
        showDataKomoditiEksternal: true,
        showDataKomoditiInternal: true,
        showDataKomoditiJasa: true,
        dt_per_kategori: [],
        dt_per_komoditi: [],
        salesOriginal:[],
        returOriginal:[],
        tanggalTabel:'',
        tabelSales: true,
        selOff: '',
        x:  window.matchMedia("(max-width: 991px)")
      }
  },
  async mounted(){
    this.getRegion()
    this.getSubRegion()
    this.getOffice()
    this.$store.dispatch('setOverlay', false)
    // this.searchSalesYear()
  },
  methods:{
    async getSalesAnalysis(){
      if(this.date_from == ''){
        this.snackbar = {
              color: "warning",
              icon: "mdi-alert-circle",
              mode: "multi-line",
              position: "top",
              timeout: 7500,
              title: "Warning",
              text: 'Start Date/Month is a must',
              visible: true
          };
          return
      }
      this.$store.dispatch('setOverlay', true)
      if(this.date_to == ''){
        console.log(this.periode)
        if(this.periode === 'Date'){
          const today = new Date();
          const year = today.getFullYear();
          const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Adds leading zero for single-digit months
          const day = today.getDate().toString().padStart(2, '0'); // Adds leading zero for single-digit days
          this.date_to = `${year}-${month}-${day}`;
        } else {
          const today = new Date();
          const year = today.getFullYear();
          const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Adds leading 0 for single-digit months
          this.date_to = `${year}-${month}`;
        }
      }
      console.log(this.date_to)
      let startPeriode = this.date_from.replace(/-/g, "")
      let endPeriode = this.date_to.replace(/-/g, "")
      let reqBody = {
          'period_type': this.periode,
          'region' : this.region,
          'subregion' : this.subregion,
          'office_id' : this.office,
          'date_from' : startPeriode,
          'date_to' : endPeriode
      }
      const respData = await salesAnalysis.getPie('', reqBody)
      if (respData.status === 200) {
          let datas = respData.data.data
          let comms = datas.commodity
          comms.forEach(item => {
              item.y = parseInt(parseFloat(item.y).toFixed(2));
          });
          let commExt = datas.commodity_ext
          commExt.forEach(item => {
              item.y = parseInt(parseFloat(item.y).toFixed(2));
          });
          let commInt = datas.commodity_int
          commInt.forEach(item => {
              item.y = parseInt(parseFloat(item.y).toFixed(2));
          });
          let sales = datas.sale
          sales.forEach(item => {
                item.x = parseInt(item.label)
                item.y = Math.floor(((item.y /1000000) * 100) / 100)
          });
          let retur = datas.retur
          retur.forEach(item => {
              item.x = parseInt(item.label)
              item.y = Math.floor(((item.y /1000000) * 100) / 100)
          });
          console.log(sales)
          console.log(retur)
          var labelNoData = "No Data Found!"
          this.showDataKategori = false
          
          this.getPieChart(comms, 'container1', 'by Category', '', labelNoData)
          this.showDataKomoditiEksternal = false
          this.getPieChart(commExt, 'container2', 'Commodity on External Category', '', labelNoData)
          this.showDataKomoditiInternal = false
          this.getPieChart(commInt, 'container3', 'Commodity on Internal Category', '', labelNoData)
          
          const data1 = {
              type: "column",
              click: this.onClick1,
              name: "Total Sales",
              indexLabel: "{y}",
              indexLabelFontSize: 14,
              showInLegend: true,
              indexLabelPlacement: "outside",  
              indexLabelOrientation: "horizontal",
          }
          const data2 = {
              type: "column",
              click: this.onClick2,
              name: "Total Retur",
              indexLabel: "{y}",
              indexLabelFontSize: 14,
              showInLegend: true,
              indexLabelPlacement: "outside",  
              indexLabelOrientation: "horizontal",
          }
          this.getChart(sales,retur, 'container5', 'Sales', '', data1, data2);

          this.$store.dispatch('setOverlay', false)
      } else {
          this.snackbar = {
              color: "error",
              icon: "mdi-alert-circle",
              mode: "multi-line",
              position: "top",
              timeout: 7500,
              title: "Warning",
              text: 'Failed to retrieve data',
              visible: true
          };
          this.$store.dispatch('setOverlay', false)
      }
    },

    async onClick1(event){
      this.$store.dispatch('setOverlay', true)
      console.log('click 1', event)
      const columnsToConvert = ['Total'];
      this.tanggalTabel = event.dataPoint.label
      this.tabelSales = true
      let reqBody = {
          'period_type': this.periode,
          'region' : this.region,
          'subregion' : this.subregion,
          'office_id' : this.office,
          'date_from' : this.tanggalTabel
      }
      const respData = await salesAnalysis.getSumOfficeSales('', reqBody)
      if (respData.status === 200) {
        this.data_sales = respData.data.data
        this.$store.dispatch('setOverlay', false)
      } else {
        this.$store.dispatch('setOverlay', false)
        this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Warning",
            text: 'Failed to retrieve data',
            visible: true
        };
        this.$store.dispatch('setOverlay', false)
      }
    },

    async onClick2(event){
      this.$store.dispatch('setOverlay', true)
      console.log('click 2', event)
      this.tanggalTabel = event.dataPoint.label
      this.tabelSales = false
      let reqBody = {
          'period_type': this.periode,
          'region' : this.region,
          'subregion' : this.subregion,
          'office_id' : this.office,
          'date_from' : this.tanggalTabel
      }
      const respData = await salesAnalysis.getSumOfficeRetur('', reqBody)
      if (respData.status === 200) {
        this.data_sales = respData.data.data
        this.$store.dispatch('setOverlay', false)
      } else {
        this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Warning",
            text: 'Failed to retrieve data',
            visible: true
        };
        this.$store.dispatch('setOverlay', false)
      }
    },

    async detail(event){
    console.log("detail", event)
      this.selOff = event.office_id
      let reqBody = {
          'period_type': this.periode,
          'region' : this.region,
          'subregion' : this.subregion,
          'office_id' : this.selOff,
          'date_from' : this.tanggalTabel
      }
      
      if(this.tabelSales){
        console.log('get sales')
        const respData = await salesAnalysis.getIndex('', reqBody)
        if (respData.status === 200) {
          this.data_pendaftarans = respData.data.data
          this.$store.dispatch('setOverlay', false)
        } else {
          this.snackbar = {
              color: "error",
              icon: "mdi-alert-circle",
              mode: "multi-line",
              position: "top",
              timeout: 7500,
              title: "Warning",
              text: 'Failed to retrieve data',
              visible: true
          };
          this.$store.dispatch('setOverlay', false)
        }
      } else {
        console.log('get retur')
        const respData = await salesAnalysis.getIndexRetur('', reqBody)
        if (respData.status === 200) {
          this.data_pendaftarans = respData.data.data
          this.$store.dispatch('setOverlay', false)
        } else {
          this.snackbar = {
              color: "error",
              icon: "mdi-alert-circle",
              mode: "multi-line",
              position: "top",
              timeout: 7500,
              title: "Warning",
              text: 'Failed to retrieve data',
              visible: true
          };
          this.$store.dispatch('setOverlay', false)
        }
      }
    },

    async exportExcel(){
      let reqBody = {
          'period_type': this.periode,
          'region' : this.region,
          'subregion' : this.subregion,
          'office_id' : this.selOff,
          'date_from' : this.tanggalTabel
      }

      if(this.tabelSales){
        const respData = await salesAnalysis.getIndex('', reqBody)
        if (respData.status === 200) {
          this.data_pendaftarans = respData.data.data
          return this.data_pendaftarans
        }
      } else {
        const respData = await salesAnalysis.getIndexRetur('', reqBody)
        if (respData.status === 200) {
          this.data_pendaftarans = respData.data.data
          return this.data_pendaftarans
        }
      }
    },
    startDownload(){
        this.$store.dispatch('setOverlay', true)
    },
    finishDownload(){
        this.$store.dispatch('setOverlay', false)
    },

    async getOffice(){
      var urlFilter = '?'
      if(this.region != '' && this.region != null){
        urlFilter = `${urlFilter}region_id=${this.region}`
      }


      this.offices=[]
      await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/getOffice`, {
        region_id : this.region ? this.region : ""
      }, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
          this.offices  = res.data.data
      })
    },
    async getRegion(){
      // var respData = await kriMaster.getRegion(``, null, false, false, false)
      // if (respData.status === 200) {
      //     this.regions = respData.data.data
      // }
      this.regions=[]
      await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/getRegion`, {

      }, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
          this.regions  = res.data.data
      })
    },

    async getSubRegion(){
      this.subregions=[]
      await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/getSubRegionV2`, {
          'region': this.region,
          'subregion_id':'',
          'subregion_sales_head':'',
          'subregion_mgr':''
      }, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      })
      .then(res => {
          this.subregions  = res.data.data
      })
    },
    
    close_inv_payment(){
      this.dialog_inv_payment = false
    },

    addSymbols(e) {
      var suffixes = ["", "K", "M", "B"];
      var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

      if(order > suffixes.length - 1)
          order = suffixes.length - 1;

      var suffix = suffixes[order];
      return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
    }, 
    toggleDataSeries(e) {
        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        } else {
            e.dataSeries.visible = true;
        }
        e.chart.render();
    }, 
    toggleDataPointVisibility(e) {
        if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
            e.dataPoint.y = e.dataPoint.actualYValue;
            e.dataPoint.actualYValue = null;
            e.dataPoint.indexLabelFontSize = null;
            e.dataPoint.indexLabelLineThickness = null;
            e.dataPoint.legendMarkerType = "circle";
        } 
        else {
            e.dataPoint.actualYValue = e.dataPoint.y;
            e.dataPoint.y = 0;
            e.dataPoint.indexLabelFontSize = 0;
            e.dataPoint.indexLabelLineThickness = 0; 
            e.dataPoint.legendMarkerType = "cross";
        }
        e.chart.render();
    }, 
    showDefaultText(chart, text) {
      var dataPoints = chart.options.data[0].dataPoints;
      var isEmpty = !(dataPoints && dataPoints.length > 0);

      if (!isEmpty) {
          for (var i = 0; i < dataPoints.length; i++) {
          isEmpty = !dataPoints[i].y;
          if (!isEmpty)
              break;
          }
      }

      if (!chart.options.subtitles)
          chart.options.subtitles = [];
      if (isEmpty) {
          chart.options.subtitles.push({
          text: text,
          verticalAlign: 'center',
          });
          chart.options.data[0].showInLegend = false;
      } else {
          chart.options.data[0].showInLegend = true;
      }
    },

    getPieChart(dt_detail, container_detail, title_detail, label_detail, labelNoData){
      var pie_chart = new CanvasJS.Chart(container_detail, {
          animationEnabled: true,
          exportEnabled: true,
          theme: "light2",
          exportEnabled: true,
          title: {
              text: title_detail,
              fontFamily: "Calibri",
              fontSize: 20
          },
          exportEnabled: true,
          subtitles:[
              {
                  text: label_detail,
                  //Uncomment properties below to see how they behave
                  fontFamily: "Calibri",
                  fontColor: "red",
                  fontSize: 12
              }
          ],
          legend: {
              itemclick: this.toggleDataPointVisibility
          },
          data: [{

              type: "pie",
              percentFormatString: "#0.##",
              indexLabel: "{label} #percent%",
              indexLabelFontSize: 12,

          }]
      });
      pie_chart.options.data[0].dataPoints = dt_detail;
      // this.showDefaultText(pie_chart, "No Data Found!");
      this.showDefaultText(pie_chart, labelNoData);

      if (this.x.matches) {

          for(var i = 0; i < pie_chart.options.data.length; i++){
              pie_chart.options.data[i].indexLabelFontSize = 6;
          }
          pie_chart.render();
      }
      pie_chart.render();
    },

    getChart(dt1, dt2, container, title, label, data1, data2){
        var chart = new CanvasJS.Chart(container, {
            animationEnabled: true,
            theme: "light2",
            exportEnabled: true,
            title: {
                text: title,
                fontFamily: "Calibri",
                fontSize: 20
            },
            exportEnabled: true,
            subtitles:[
                {
                    text: label,
                    //Uncomment properties below to see how they behave
                    fontFamily: "Calibri",
                    fontColor: "red",
                    fontSize: 12
                }
            ],
            axisY: {
                // scaleBreaks: {
                //     autoCalculate: true,
                //     type: "wavy"
                // },
                crosshair: {
                    enabled: true,
                    snapToDataPoint: true
                },
                title: "Million",
                labelFormatter: this.addSymbols,
            },
            toolTip:{
                shared:true
            },
            legend: {
                cursor: "pointer",
                itemclick: this.toggleDataSeries,
                fontSize: 11
            },
            data: [ data1, data2]
        });
        chart.options.data[0].dataPoints = dt1;
        chart.options.data[1].dataPoints = dt2;
        this.showDefaultText(chart, "No Data Found!");
        var x = window.matchMedia("(max-width: 991px)");
        if (x.matches) {

            for(var i = 0; i < chart.options.data.length; i++){
                chart.options.data[i].indexLabelFontSize = 8;
            }
            chart.render();
        }
        chart.render();  
    },
  },
  watch: {
      option_item_other_payments:{
        handler () {
            // this.loading_other_payment = true
            const { page, itemsPerPage } = this.option_item_other_payments
            if (this.period_other_payment) {
                this.getItemOtherPayment(page, itemsPerPage, this.period_other_payment)
            }
        },
        deep: true,
      },
      option_item_payments:{
        handler () {
              // this.loading_payment = true
              const { page, itemsPerPage } = this.option_item_payments
              if (this.period) {
                  this.getItemPayment(page, itemsPerPage, this.period)
              }
          },
          deep: true,
      },
      // option_item_inv_payments:{
      //   handler () {
      //         // this.loading_inv_payment = true
      //         const { page, itemsPerPage } = this.option_item_inv_payments
      //         if (this.period_inv) {
      //             this.getItemInvPayment(page, itemsPerPage, this.period_inv)
      //         }
      //     },
      //     deep: true,
      // },
      option_item_shops:{
        handler () {
              // this.loading_shop_last_month = true
              const { page, itemsPerPage } = this.option_item_shops
              if (this.last_month) {
                  this.getItemShop(page, itemsPerPage, this.last_month)
              }
          },
          deep: true,
      },
  }
}
</script>